/* EIV CSS */

.fixed-top { 
  z-index: 999; 
  padding-right: max(constraint(safe-area-inset-right), 16px);
  padding-right: max(env(safe-area-inset-right), 16px);
  padding-left: max(constraint(safe-area-inset-left), 16px);
  padding-left: max(env(safe-area-inset-left), 16px);
} 
body{
  background-color: #f8f9fa; 
  background-image: url(./resources/fotolia69013197.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: rgba(240, 240, 240, 0.918); 
  opacity: 0.95;
  padding-top: 6em;
}
@supports (padding-top: constant(safe-area-inset-top)) {
  body{
    --safe-area-inset-top: constant(safe-area-inset-top);
    padding-top: calc(var(--safe-area-inset-top) + 76px);
  }
  .fixed-top{
    --safe-area-inset-top: constant(safe-area-inset-top);
    padding-top: calc(var(--safe-area-inset-top) + 0px);
  }
}
@supports (padding-top: env(safe-area-inset-top)) {
  body{
    --safe-area-inset-top: env(safe-area-inset-top);
    padding-top: calc(var(--safe-area-inset-top) + 76px);
  }
  .fixed-top{
    --safe-area-inset-top: env(safe-area-inset-top);
    padding-top: calc(var(--safe-area-inset-top) + 0px);
  }
}
.toScrollUp{
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 3s, 0s;
  transition-delay: 0s, 3s;
}
.align-items-center{
  align-items: center
}
.clickableValue {
  cursor: pointer;
}

.GNVPVGB-S-a {
  position: fixed;
  width: 100%;
  z-index: 1900;
  pointer-events: none;
  top: 0;
  transition: transform 150ms cubic-bezier(0.4,0.0,1,1);
}
.GNVPVGB-fb-a {
  background: #c6dafc;
  width: 100%;
  height: 5px;
  position: relative;
  overflow: hidden;
}
.GNVPVGB-fb-b {
  animation: sliderAAnimation 4s infinite linear;
}
.GNVPVGB-fb-c {
  animation: sliderBAnimation 4s infinite linear;
}
.GNVPVGB-fb-b, .GNVPVGB-fb-c {
  background: #4285f4;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform-origin: left;
}
@media (max-width: 767.98px) { /* Bootstrap 4 md breakpoint is 768px */
  .btn-md-block {
    display: block;
    width: 100%;
  }
}
